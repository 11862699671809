import React from 'react';

const Header: React.FC = () => {
  return (
    <header className={'header'}>
      <div className={'desktop'}>
        <div className={'topbar column'}>
          <div className={'topbar-left'}>
            <div className={'topbar-logo'}>
              <img
                alt='tipos-logo'
                className={'topbar-logo-image'}
                src='https://www.tipos.sk/TiposTheme/assets/images/header/logo-tipos.svg'
              ></img>
            </div>
          </div>
          <div className={'topbar-center'}>
            <div className={'topbar-center-title'}>Pripojenie k agentovi</div>
          </div>
          <div className={'topbar-right'}></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
