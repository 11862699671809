import axios from "axios";
import Config from "./Config";
import { IAgentData } from "./Types";

export const agentConnectionsApi = axios.create({
  baseURL: Config.apiUrl,
  withCredentials: false,
});

agentConnectionsApi.defaults.headers.common["Content-Type"] =
  "application/json";

export const getConnectedAgentByAcsId = async (acsId: string) => {
  const response = await agentConnectionsApi.get<IAgentData>(
    `AgentConnections/GetConnectedAgent?acsId=${acsId}`
  );
  return response.data;
};

export const getConnectedIsAgentConnected = async (acsId: string) => {
  const response = await agentConnectionsApi.get<boolean>(
    `AgentConnections/GetConnectedAgentIsConnected?acsId=${acsId}`
  );
  return response.data;
};
