import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { ACSServiceProvider } from './services/ACSServiceContext';
import { initializeIcons, registerIcons } from '@fluentui/react';
import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

ReactDOM.render(
  <FluentProvider theme={teamsLightTheme}>
    <React.StrictMode>
      <ACSServiceProvider>
        <App />
      </ACSServiceProvider>
    </React.StrictMode>
  </FluentProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
