import axios from 'axios';
import { ICallSessionData } from './Types';
import Config from './Config';

export const connectionLinksApi = axios.create({
  baseURL: Config.apiUrl,
  withCredentials: false,
});

connectionLinksApi.defaults.headers.common['Content-Type'] = 'application/json';

export const getCallSession = async (sessionId: string) => {
  const response = await connectionLinksApi.get<ICallSessionData>(
    `ConnectionsLinks/GetCallSession?sessionId=${sessionId}`
  );
  return response.data;
};

export const endSession = async (sessionId: string) => {
  await connectionLinksApi.delete(`ConnectionsLinks?sessionId=${sessionId}`);
};
