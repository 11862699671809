import React, { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import "./styles.css";
import CallPage from "./components/CallPage";
import {
  CallClientProvider,
  CallAgentProvider,
  StatefulCallClient,
} from "@azure/communication-react";
import { CallAgent } from "@azure/communication-calling";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import ACSService from "./services/ACSService";
import ACSServiceContext from "./services/ACSServiceContext";

type AppState = {
  permissionStatus: "unknown" | "granted" | "denied";
};

const App: React.FC = () => {
  const [statefulCallClient, setStatefulCallClient] =
    useState<StatefulCallClient>();
  const [callAgent, setCallAgent] = useState<CallAgent>();

  const [isValid, setIsValid] = useState<boolean>();
  const [permissionStatus, setPermissionStatus] =
    useState<AppState["permissionStatus"]>("unknown");
  const acsServiceInstance = useContext(ACSServiceContext) as ACSService;

  useEffect(() => {
    checkAudioPermission();
    initCallAgent();
  }, []);

  const initCallAgent = () => {
    if (callAgent === undefined) {
      acsServiceInstance.createCallClient().then((result) => {
        if (result && result.callClient && result.callAgent) {
          setStatefulCallClient(result.callClient);
          setCallAgent(result.callAgent);
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      });
    }
  };

  const checkAudioPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // User has granted audio permission
      setPermissionStatus("granted");
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      // User has denied audio permission or some error occurred
      setPermissionStatus("denied");
    }
  };

  return (
    <>
      {isValid != null && !isValid && (
        <Dialog open={!isValid}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Upozornenie</DialogTitle>
              <DialogContent>
                Tento odkaz už nie je aktuálny, prosím kontaktuje Live Chat
                agenta.
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
      {statefulCallClient && (
        <CallClientProvider callClient={statefulCallClient}>
          {callAgent && (
            <CallAgentProvider callAgent={callAgent}>
              <div>
                <Header />
                {callAgent && (
                  <CallPage
                    permissionStatus={permissionStatus}
                    callAgent={callAgent as CallAgent}
                  />
                )}
              </div>
            </CallAgentProvider>
          )}
        </CallClientProvider>
      )}
    </>
  );
};

export default App;
